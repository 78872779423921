import React, {Fragment, useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {CardContent, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Spring, useSpring, animated as a } from "react-spring";
import Stack from "@mui/material/Stack";
import AwesomeSlider from 'react-awesome-slider';

import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay'
import withAutoplay2 from 'react-awesome-slider/dist/autoplay2'


import AwsSliderStyles from 'react-awesome-slider/src/core/styles.scss';
import CaptionedStyles from 'react-awesome-slider/src/hoc/captioned-images/styles.scss';



const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        backgroundColor: '#e4e7eb',
        //backgroundColor: '#282c34',
    },
    avatar: {
        backgroundColor: theme.palette.white,
        color: theme.palette.info.main,
        width: theme.spacing(9),
        height: theme.spacing(9),
        boxShadow: '2px 2px 6px 0px rgba(0, 0, 0, 0.75)',
        border: '1px solid ' + theme.palette.info.light,
        zIndex: "-30vh"
    },
    icon: {
        height: 128,
        width: 128
    },
    text: {
        color: 'rgb(104,104,104)',

    },
    message: {
        height: '100%',
        boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.75)',
        //   border: `1px solid`,
        color: theme.palette.info.light,
        borderColor: theme.palette.info.light,
        //backgroundColor: "#D4D4D4"

    },
    content: {
        padding: theme.spacing(1),
        //backgroundColor: '#282c34',
        display: 'flex',
        flexDirection: 'column',
    },
    divider: {
        color: 'blue',
        "&.MuiDivider-root": {
            "&::before": {
                borderTop: "3px solid red"
            },
            "&::after": {
                borderTop: "3px solid red"
            }
        },
        "& .MuiDivider-wrapper": {
            fontSize: 16,
            fontWeight: 'bold'
        }
    }
}));

function TopBar() {
    return (  <Stack>
        <img src="static/images/logo.png" />
        {/*<Box sx={{m: 1}}/>*/}
        {/*<img src="static/images/value-creator.png"/>*/}
    </Stack>);
}

function Expertise() {
    const classes = useStyles();

    return(<div id="knowledge" data-aos={"fade-up"} data-aos-delay="200" data-aos-offset="-400">
        <Box sx={{m: 4}}/>
        <Divider classes={{root: classes.divider}}>NOTRE SAVOIR FAIRE</Divider>
        <br/>
        <Typography className={classes.text} variant="body1">
            Nous intervenons dans le respect des règles et des niveaux d’excellences définies par La
            Royal Institution of Chartered Surveyors (RICS)
        </Typography>

        <Box display="flex"
             alignItems="right"
             justifyContent="right">
            <IconButton sx={{ alignItems: 'center'}} onClick={() => window.open("https://www.rics.org")}>
                <img src="static/images/rics-logo.png"/>
            </IconButton>
        </Box>
    </div>);
}

function Edito() {
    const classes = useStyles();

    return (
        <>
        <Divider classes={{root: classes.divider}}>EDITO</Divider>
        <br/>
        <Typography className={classes.text} variant="body1" align={'justify'}>

            Notre stratégie consiste à apporter une offre globale de services à forte valeur ajoutée. L’objectif étant
            de définir les solutions les plus avantageuses pour nos clients, les plus intéressantes pour nos
            investisseurs et les plus durables pour les générations futures.<br/><br/>

            Notre vision globale nous permet, à travers nos compétences multidisciplinaires, de se positionner
            comme interlocuteur unique, intervenant sur les différentes phases de la réalisation des opérations
            immobilières.<br/><br/>

            Notre offre de services permet de répondre de la manière la plus efficiente possible aux besoins de nos
            clients. Nous intervenons sur l’ensemble des produits immobiliers : logement, immobilier d’entreprise,
            hôtellerie, résidence de tourisme, résidence de santé, résidence seniors et logistique.<br/><br/>

            Notre ambition est de répondre aux besoins de redéveloppement des actifs immobiliers tout en
            accompagnant nos clients dans la réalisation d’opérations complexes.
        </Typography>
        </>);
}

function MessageCard() {
    const classes = useStyles();

    return (
        <Box display="flex" justifyContent="top" alignItems="top" p={2}>
            <Stack direction={'row'} spacing={1}>
                    <Avatar alt="Ahmed BEN AYED" className={classes.avatar} style={{justifyContent: "top", display: "flex"}} style={{zIndex: 1}} src="static/images/ahmed-ben-ayed.png"/>

                    <Stack direction={'column'}>
                        <Typography color={'rgb(104,104,104)'} sx={{textAlign: 'justify'}} variant="body2"><FormatQuoteIcon/>
                            Notre idée fondatrice est de définir la valeur optimale des biens immobiliers à travers un
                            redéveloppement durable, dans le respect de l’environnement urbain et en partenariat avec les
                            utilisateurs et les acteurs locaux.</Typography>
                        <Typography color={'rgb(0,32,96)'} variant="subtitle2" align={'right'}>Ahmed BEN AYED - Président - MRICS</Typography>
                    </Stack>
            </Stack>
        </Box>
);
}

function BottomBar() {
    return (<Grid
        container
        alignItems="flex-start"
        sx={{backgroundColor: "#DCDCDC"}}
    >
        <Grid
            item
            lg={1}
        />
        <Grid
            item
            lg={3}

        > <Typography sx={{fontWeight: 'bold'}} variant="overline" display="block" gutterBottom>NOUS
            CONTACTER</Typography>

        </Grid>
        <Grid
            item
            lg={4}
        >
            <Typography variant="overline" display="block" gutterBottom>813, avenue Général LECLERC
                92100, Boulogne-Billancourt<br/>
                TEL: 33 (0) 6 12 76 96 41<br/>
                MAIL: <a href= "mailto:ahmed.benayed@dimaconseil.fr">ahmed.benayed@dimaconseil.fr</a>
            </Typography>
        </Grid>
        <Grid
            item
            lg={2}
        >
            <IconButton onClick={() => window.open("https://www.google.com/maps/place/813+Av.+du+G%C3%A9n%C3%A9ral+Leclerc,+92100+Boulogne-Billancourt/@48.8300995,2.2312865,17z/data=!3m1!4b1!4m5!3m4!1s0x47e67ae305798cc7:0x16fa6c21063b2972!8m2!3d48.8300995!4d2.2334805?hl=fr")}>
                <img src="static/images/google-map-location.png"/>
            </IconButton>
        </Grid>

        <Grid
            item
            lg={2}
        />
    </Grid>);
}

function RealEstateDev() {
    const classes = useStyles();

    return(<div id="development" data-aos={"fade-up"} data-aos-delay="300" data-aos-offset="-400">
    <Divider classes={{root: classes.divider}}>DEVELOPPEMENT IMMOBILIER</Divider>
    <br/>
    <Typography align={'justify'} variant="body1" sx={{color: 'rgb(104,104,104)'}}>
        Notre savoir-faire réside dans le redéveloppement des actifs immobiliers à travers des changements
        de destination, de rénovations lourdes et des opérations de réhabilitation.<br/><br/>
        Nous mettons en place des stratégies de prévente et/ou de pré-commercialisation. Nous organisons
        par la suite des tours de table avec nos différents partenaires, promoteurs et/ou investisseurs, pour
        assurer le financement des projets.
    </Typography>
</div>);
}

function StrategyAdvice() {
    const classes = useStyles();

    return(<div id="strategy" data-aos={"fade-up"} data-aos-delay="300" data-aos-offset="-400">
    <Divider classes={{root: classes.divider}}>CONSEIL EN STRATEGIE IMMOBILIERE</Divider>
    <br/>
    <Typography  align={'justify'} variant="body1" sx={{color: 'rgb(104,104,104)'}}>
        Fort de nos expériences dans la promotion immobilière et l’asset management, nous avons développé
        un savoir-faire et des outils de modélisation nous permettant d&#39;analyser différents scénarios de
        valorisation.<br/><br/>
        Notre mission est de définir la stratégie la plus adéquate pour nos clients.
    </Typography>
</div>);
}

function ProjectManagement() {
    const classes = useStyles();

    return(<div id="projectmgt" data-aos={"fade-up"} data-aos-delay="300" data-aos-offset="-400">
    <Divider classes={{root: classes.divider}}>MANAGEMENT DE PROJET</Divider>
    <br/>
    <Typography  align={'justify'} variant="body1" sx={{color: 'rgb(104,104,104)'}}>
        L’activité de management de projet est le corps de notre métier. Nous intervenons dans le cadre de
        mandats de délégation en tant que Maîtrise d’Ouvrage Déléguée (MOD), ou en tant qu’Assistance à la
        Maîtrise d’Ouvrage (AMO).<br/><br/>
        Nous accompagnons nos clients à chaque étape du développement de leurs projets, en mettant à leur
        disposition notre expérience et nos compétences dans les aspects juridiques, administratifs et
        financiers. Cela comprend la définition du programme, l'assistance et le suivi pour l’élaboration et
        l'obtention des permis de construire, l'établissement du budget, la mise en place du bilan financier,
        ainsi que la constitution de l'équipe projet et la consultation des entreprises.<br/><br/>

        Pendant la phase de travaux, nous accompagnons nos clients dans le suivi des travaux et du budget, le
        contrôle du planning, la participation aux réunions de chantier, ainsi que l'assistance à la livraison et à
        la levée des réserves.
    </Typography>
</div>);
}

function Trust() {
    const classes = useStyles();

    return(<>
        <Divider classes={{root: classes.divider}}>CEUX QUI NOUS FONT CONFIANCE</Divider>
        <br/>
        <Box display="flex"
             alignItems="center"
             justifyContent="center">
            <img src="static/images/martin.jpg"/>
            <img src="static/images/pmi.png"/>
            <img src="static/images/lagenerale.png"/>
        </Box>
        </>
    );
}

function References() {
    const classes = useStyles();
    const AutoplaySlider = withAutoplay2(AwesomeSlider);
    const slideImages = [
        {
            backgroundColor: '#5974FC',
            path: 'static/images/references/palazzo/1.jpg',
            text: 'PALAZZO   -   Résidence services séniors haut de gamme   -   Saint Mandé'
        },
        {
            backgroundColor: '#5974FC',
            path: 'static/images/references/palazzo/2.jpg',
            text: 'PALAZZO   -   Résidence services séniors haut de gamme   -   Saint Mandé'
        },
        {
            backgroundColor: '#5974FC',
            path: 'static/images/references/bio-park/1.jpg',
            text: 'BIO-PARK   -   Réindustrialisation d’un ancien site industriel de 45 hectares   -   Région Pays de la Loire'
        },
        {
            backgroundColor: '#5974FC',
            path: 'static/images/references/bio-park/2.jpg',
            text: 'BIO-PARK   -   Réindustrialisation d’un ancien site industriel de 45 hectares   -   Région Pays de la Loire'
        }
    ];

    return(<>
        <Box sx={{m: 8}}/>
        <Divider classes={{root: classes.divider}}>NOS DERNIERES REFERENCES</Divider>
        <br/>

        <AutoplaySlider class="references" id="references" key="references" style={{"--slider-height-percentage": "40%"}} play={true} cssModule={CaptionedStyles}
                       mobileTouch={true}
                      // organicArrows={false}
                       infinite={true}
                       startup={true}
                       startupDelay={0}
                        fillParent={false}

        >
            {slideImages.map((slideImage, index)=> (
                <div key={index} data-src={slideImage.path} style={{backgroundColor: slideImage.backgroundColor}}>
                    <div data-type="caption" style={{backgroundColor: slideImage.backgroundColor,fontSize: 20}}>
                        <p>{slideImage.text}</p>
                    </div>
                </div>
            ))}
        </AutoplaySlider>
        <Box sx={{m: 4}}/>
    </>);
}

function Banner() {
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const slideImages = [
        {
            backgroundColor: '#5974FC',
            path: 'static/images/banner/1.jpg',
        },
        {
            backgroundColor: '#5974FC',
            path: 'static/images/banner/2.jpg',
        }
    ];

    return(<>
        <AutoplaySlider classes="banner" id="banner" key="banner" style={{"--slider-height-percentage": "20%"}} play={true} cssModule={AwsSliderStyles}
                        mobileTouch={true}
                        infinite={true}
                        startup={true}
                        startupDelay={0}
                        bullets={false}
                        organicArrows={false}

        >
            {slideImages.map((slideImage, index)=> (
                <div key={index} data-src={slideImage.path} style={{'object-fit': 'scale-down',height: "100%", backgroundColor: slideImage.backgroundColor}}>
                </div>
            ))}
        </AutoplaySlider>
    </>);
}


export default function Home() {
    const classes = useStyles();
    useEffect(() => {
        Aos.init({duration: 1000,anchorPlacement: 'top-center'});
    },[])

    const logoAnimation = useSpring({
        config: {duration: 500},
        loop: false,
        to: [
            { opacity: 1, marginTop: "0", transform:`scale(1)`},
        ],
        from: { opacity: 0, marginTop: "50vh", transform:`scale(2)`},
    })

    return (
        <div className={classes.content} style={{background: '#FFFFFF'}}>
            <Paper elevation={1} style={{background: '#FFFFFF'}}>
                <div>
                    <Grid container mt={1} columnSpacing={3} alignItems="top" spacing={3} >
                        {/*LOGO*/}
                        <Grid item lg={4} sm={4} xl={4} xs={12} style={{textAlign: "top"}} alignItems="top">
                            <a.div style={logoAnimation}>
                                <TopBar/>
                            </a.div>
                        </Grid>
                        <Grid item lg={3} sm={3} xl={3} xs={0}/>

                        <Grid item lg={5} sm={5} xl={5} xs={12}>
                            <div id="message" data-aos="fade" data-aos-delay="500" data-aos-offset="0">
                                <MessageCard/>
                            </div>
                        </Grid>

                        <Grid item lg={12} sm={12} xl={12} xs={12} sx={{height: "40%"}}>
                            <div  style={{height: "20%"}} id="banner" data-aos="fade" data-aos-delay="500" data-aos-offset="0">
                                <Banner/>
                            </div>
                        </Grid>

                        {/*EDITO*/}
                        <Grid item lg={1} sm={1} xl={1} xs={0}/>
                        <Grid item lg={10} sm={10} xl={10} xs={12}>
                            <div id="edito" data-aos={"fade-up"} data-aos-delay="500" data-aos-offset="-400">
                                <Edito/>
                            </div>
                        </Grid>
                        <Grid item lg={1} sm={1} xl={1} xs={0}/>

                        {/*Dernieres References*/}
                        <Grid item lg={1} sm={1} xl={1} xs={0}/>
                        <Grid item lg={10} sm={10} xl={10} xs={12}>
                            <div id="refs" data-aos={"fade-up"} data-aos-delay="500" data-aos-offset="-400">
                                <References/>
                            </div>
                        </Grid>
                        <Grid item lg={1} sm={1} xl={1} xs={0}/>

                        {/*SAVOIR FAIRE*/}
                        <Grid item lg={1} sm={1} xl={1} xs={0}/>
                        <Grid item lg={10} sm={10} xl={10} xs={12}>
                            <div id="expertise" data-aos={"fade-up"} data-aos-delay="500" data-aos-offset="-400">
                                <Expertise/>
                            </div>
                        </Grid>
                        <Grid item lg={1} sm={1} xl={1} xs={0}/>

                        {/*DEV-STATEGIE-MANAGEMENT*/}
                        <Grid item lg={12}>
                            <Grid container mt={1} p={1} columnSpacing={6} spacing={6} alignItems="flex-start">
                                <Grid item lg={4}>
                                    <div id="RealEstateDev" data-aos={"fade-up"} data-aos-delay="500" data-aos-offset="-400">
                                    <RealEstateDev/>
                                    </div>
                                </Grid>
                                <Grid item lg={4}>
                                    <div id="StrategyAdvice" data-aos={"fade-up"} data-aos-delay="500" data-aos-offset="-400">
                                    <StrategyAdvice/>
                                    </div>
                                </Grid>
                                <Grid item lg={4}>
                                    <div id="ProjectManagement" data-aos={"fade-up"} data-aos-delay="500" data-aos-offset="-400">
                                    <ProjectManagement/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/*CONFIANCE*/}
                        <Grid item lg={3} />
                        <Grid item lg={6}>
                            <div id="trust" data-aos={"fade-up"} data-aos-delay="500" data-aos-offset="-400">
                                <Trust/>
                            </div>
                        </Grid>
                        <Grid item lg={3}/>

                        <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <div id="bottom" data-aos={"fade-up"} data-aos-delay="500" data-aos-offset="-400">
                                <BottomBar/>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </div>
    );

}
