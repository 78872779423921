import {createTheme, adaptV4Theme, createMuiTheme} from '@mui/material';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';




const themee = createTheme(adaptV4Theme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  },

}));
const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },

}, themee);

export default theme;
