import Home from "./pages/Home";
import React, {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {ThemeProvider} from "@mui/styles";
import {StyledEngineProvider} from "@mui/material";
import theme from "../themes/default";


export function App(){

  return (
      <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
          <Home/>
          </ThemeProvider>
      </StyledEngineProvider>
  )
}

